
















































































































import axios, { AxiosRequestConfig, Method, ResponseType } from 'axios';
import moment from 'moment';
import Component from 'vue-class-component';

import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import { InactivityWatcher } from '@/inactivityWatcher';
import { MUT_SNACKBAR } from '@/store';

@Component({
  components: {
    UiButton,
    UiDatePicker,
    UiLoading,
  },
})
export default class Export extends BaseVue {
  public compareDate = '';
  public isLoading = false;
  public exportMode = 'Transaction Lines';
  public isNew = true;

  public baseUrl = process.env.VUE_APP_RPT_API_URL ?? process.env.VUE_APP_API_URL;
  public balanceCheckReport: any = null;
  public reportCSV = '';
  public reportSuccess = false;
  public reportElapsedTime? = '';

  public get headers() {
    return Object.values(this.columns).map((x) => x.heading);
  }

  public columns = {
    // walletid: { heading: 'Wallet ID' } CAN RE-ADD IN FUTURE IF WE WANT,
    wallet_name: { heading: 'Wallet Name' },
    asset_symbol: { heading: 'Asset Symbol' },
    bitwave_balance_time: { heading: 'Bitwave Balance Time' },
    bitwave_balance: { heading: 'Bitwave Balance' },
    thirdparty_balance: { heading: 'Third Party Balance' },
    difference: { heading: 'Difference' },
    thirdparty_balance_time: { heading: 'Third Party Balance Time' },
  };

  isValidDate(dateString: string) {
    // Parse the date string into a Date object
    var dateObj = new Date(dateString);

    // Check if the date object is valid and the string matches the expected format
    return !isNaN(dateObj.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  }

  get isValid() {
    return this.isValidDate(this.compareDate);
  }

  public handleDownloadReport() {
    if (!this.reportCSV) return;
    const orgId = this.$store.state.currentOrg.id;

    // Create a Blob object from the file data
    const blob = new Blob([this.reportCSV], { type: 'text/csv' });
    // Create a download link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `balance_check_report_${orgId}_${Date.now()}.csv`;

    // Append the link to the document body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document body
    document.body.removeChild(link);
  }

  public async handleRunReport() {
    try {
      this.isLoading = true;
      this.reportElapsedTime = undefined;

      const orgId = this.$store.state.currentOrg.id;
      let exportUrl =
        this.baseUrl + 'orgs/' + this.$store.state.currentOrg.id + '/inventory-views/reports/balance-check-report';

      exportUrl = exportUrl + `?endDate=${this.compareDate}&exportResults=true`;

      const reportStartTime = Date.now();
      const resp = await axios({ method: 'get', url: exportUrl, withCredentials: true });
      this.balanceCheckReport = resp.data?.metadata;
      this.reportCSV = resp.data?.csv;
      this.reportElapsedTime = this.getElapsedTime(reportStartTime);

      this.reportSuccess = true;
    } catch (error) {
      this.showErrorMessage();
    } finally {
      this.isLoading = false;
    }
  }

  showErrorMessage() {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'error',
      message: 'Failed to run report',
    });
  }

  showSuccessMessage() {
    this.$store.commit(MUT_SNACKBAR, {
      color: 'success',
      message: 'File download completed',
    });
  }
}
